'use client';

import { createContext } from 'react';
import { OwApp } from '../interfaces/domain/ow-app.modal';

export type TAppPageContext = {
  app: OwApp;

}

const AppPageContext = createContext<TAppPageContext>({
  app: null,
});

export default AppPageContext;
