import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Review } from '../interfaces/domain/reviews.modal';
import { IReviewsService } from '../interfaces/services/reviews-service.interface';
import { kReviewsPageSize } from '../services/constants';
import { ServicesHandler } from '../services/service-handler';
import GlobalContext from '../context/global-context';

interface UseReviewsHook {
  reviews: Review[];
  slideReviews: Review[];
  isLoading: boolean;
  loadMoreReviews: (appId: string, page: number) => Promise<void>;
}

const findSlideReviews = (reviews: Review[]): Review[] => {
  if (!reviews || reviews.length === 0) {
    return null;
  }

  const defaultAmountOfSlideReviews = 6;

  const reviewsWithText = reviews.filter(review => {
    return review.comment_content !== '';
  });

  const amountToSlice =
  reviewsWithText.length > defaultAmountOfSlideReviews
    ? defaultAmountOfSlideReviews
    : reviewsWithText.length;

  return reviewsWithText
    .sort((a, b) => {
      return b.rate - a.rate;
    })
    .slice(0, amountToSlice);
};

export const useReviews = (initialReviews: Review[]): UseReviewsHook => {
  const reviewsServiceRef = useRef<IReviewsService>(null);
  const [reviews, setReviews] = useState<Review[]>(initialReviews);
  const [slideReviews] = useState<Review[]>(findSlideReviews(initialReviews));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { config } = useContext(GlobalContext);

  useEffect(() => {
    const { reviewsService } = ServicesHandler.getInstance(config);
    reviewsServiceRef.current = reviewsService;
  }, []);

  const loadMoreReviews = useCallback(
    async (appId: string, page: number): Promise<void> => {
      setIsLoading(true);
      const newReviews = await reviewsServiceRef.current.getAdditionalReviews(
        appId,
        page,
        kReviewsPageSize
      );

      setIsLoading(false);
      if (!newReviews) return;

      setReviews(cur => [...cur].concat(newReviews));
    },
    []
  );

  return {
    reviews,
    isLoading,
    slideReviews,
    loadMoreReviews,
  };
};
