'use client';

import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';
import SpriteIcon from '../shared/sprite-icon';
import GlobalContext from '../../context/global-context';

export const BrowseMoreApps = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { config } = useContext(GlobalContext);
  const { t } = useTranslation();
  const browseMore = app.richDetails?.browseMore || null;

  const browseByGame  = config?.publicRuntimeConfig?.links?.browseByGame;

  if (!browseMore || browseMore.length === 0) {
    return null;
  }

  return (
    <section className='browse-more-apps-section'>
      <h2>{t('browseMoreApps')}</h2>

      <ul className='browse-more-apps-list'>
        {browseMore.map((item, index) => {
          return (
            <li className='app-item' key={index}>
              <a href={item.link}>
                <img src={item.img} alt={item.name} />
                <h6>{item.name}</h6>
              </a>
            </li>
          );
        })}
      </ul>

      <a
        className='clean-btn'
        href={`${browseByGame}/view-all`}
      >{t('viewAll')}
        <svg>
          <SpriteIcon icon='arrowRight' />
        </svg>
      </a>
    </section>
  );
};
