import { FC, useMemo } from 'react';
import { Review } from '../../interfaces/domain/reviews.modal';
import { useDateFormat } from '../../hooks/use-date-format';

interface Props {
  review: Review;
}

export const ReviewSliderItem: FC<Props> = props => {
  const { review } = props;

  const rateStyle = useMemo<React.CSSProperties>(() => {
    return { '--rating': review.rate } as React.CSSProperties;
  }, [review.rate]);

  return (
    <li className='swiper-slide'>
      <div className='review'>
        <div className='review-header'>
          <h4 className='title'>{review.comment_author}</h4>
          <span className='date'>{useDateFormat(review.comment_date_gmt)}</span>
        </div>

        <div className='ratings'>
          <div className='star-rating' style={rateStyle} />
        </div>

        <div className='review-content'>
          <p dangerouslySetInnerHTML={{
            __html: review.comment_content.replace(/\n/g, '<br />')
          }}
          />
        </div>
      </div>
    </li>
  );
};
