export const getQueryParam = (search: string, param: string): string | null => {
  const params = new URLSearchParams(search);
  const lowerCaseParam = param.toLowerCase();

  for (const [key, value] of params.entries()) {
    if (key.toLowerCase() === lowerCaseParam) {
      return value;
    }
  }

  return null;
};