import {useContext, useState } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';
import { useClassName } from '../../hooks/use-class-name';
import { GameSearchFilter } from './search-filter';

export const SupportedGamesModal = (): JSX.Element => {

  const [searchText, setSearchText] = useState<string>('');
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const supportedGames = app.richDetails?.supportedGames;
  const sgListClassName = useClassName({
    'is-loading': false,
  }, 'sg-list-wrapper');

  if (!supportedGames) {
    return (<></>);
  }

  const onSearchTextChange = (value: string): void => {
    setSearchText(value);
  };

  const clearSearch = (): void => {
    setSearchText('');
  };

  const filteredGames = supportedGames.filter(({ name }) =>
    name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className='supported-games-modal-content'>
      <div className='sg-header'>
        <div className='app-thumb'>
          <img src={app.iconUrl} alt={app.title} />
        </div>
        <div className='app-title'>
          <h2>{app.title}</h2>
          <p>
            {t('supportedGames')} {/* (
            <span className='amount-data'>
              {supportedGames.length}
            </span>
              ) */}
          </p>
        </div>
      </div>

      <div className='sg-search'>
        <GameSearchFilter
          placeholder='Enter game name'
          onSearchTextChange={onSearchTextChange}
          clearSearch={clearSearch}
          searchText={searchText}
        />
      </div>

      <div className={sgListClassName}>

        {filteredGames.length > 0 &&
          <ul className='sg-full-list'>
            {filteredGames.map((game, index) => (
              <li className='sgf-list-item' key={index}>
                {game.name}
              </li>
            ))}
          </ul>
        }

        {filteredGames.length === 0 &&
          <div className='no-results-placeholder'>
            <p>{t('supportedGamesSearchNoResultsPlaceHolder')}</p>
          </div>
        }

      </div>

      <div className='sg-footer'>
        <p dangerouslySetInnerHTML={{__html: t('supportedGamesSubTitle')}} />
      </div>
    </div>
  );
};
