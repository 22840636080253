import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';
import { ImageSlider } from '../image-slider/image-slider';
import { ImageVideoSlider } from '../image-video-slider/image-video-slider';

// -----------------------------------------------------------------------------
export const SliderWrapper: React.FC = () => {
  const { app } = useContext(AppPageContext);

  if (!app.richDetails?.richScreenShots ||
    app.richDetails.richScreenShots.length === 0) {
    return (
      <ImageSlider />
    );
  }

  return <ImageVideoSlider />;
};