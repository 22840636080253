import { FC, useContext, useEffect, useRef, useState } from 'react';
import YouTubePlayer from 'react-player/youtube';
import ReactPlayer from 'react-player/youtube';
import useBus from '../../../hooks/use-bus';
import { AppBusEventType } from '../../../interfaces/bus-events';
import GlobalContext from '../../../context/global-context';

// -----------------------------------------------------------------------------
interface VideoProps {
  alt: string;
  ytID: string;
}

// -----------------------------------------------------------------------------
export const LargeVideoItem: FC<VideoProps> = ({ ytID, alt }): JSX.Element => {
  const { config } = useContext(GlobalContext);
  const { videoUrl, thumbnailPlaceHolder } =
    config?.publicRuntimeConfig?.externalLinks.youtube;

  const [hasWindow, setHasWindow] = useState<boolean>(false);
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const player = useRef<YouTubePlayer>(null);
  const ytURL = `${videoUrl}${ytID}`;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  useBus(AppBusEventType.App.PlayVideoSlider, event => {
    if (event.data.ytID === ytID) {
      setIsPlay(true);
    }

    if (event.data.stopAll) {
      setIsPlay(false);
    }
  });

  return (
    <>
      <div className='video-container'>
        <img
          src={thumbnailPlaceHolder}
          alt={`${alt} screenshot image`}
        />

        {hasWindow &&
          <ReactPlayer
            className='slider-video'
            id={ytID}
            ref={player}
            url={ytURL}
            width='100%'
            height='100%'
            controls={true}
            playing={isPlay}
            rel={0}
          />
        }
      </div>
    </>
  );
};
