import { SupportedGame } from '../../interfaces/domain/ow-app.modal';
import { handleToolTipMouseOut, handleToolTipOnOver } from '../shared/ow-tool-tip';

interface previewItemImageProps {
  item: SupportedGame;
}

// -----------------------------------------------------------------------------
export const PreviewItemImage: React.FC<previewItemImageProps> = props =>{
  const {item} = props;

  if (!item) {
    return (<></>);
  }

  return (
    <img
      src={item.iconSrc}
      alt={item.name}
      data-tooltip={item.name}
      onMouseOver={handleToolTipOnOver}
      onMouseOut={handleToolTipMouseOut}
    />
  );

};
