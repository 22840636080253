import { FC } from 'react';
import { SupportedGame } from '../../interfaces/domain/ow-app.modal';
import { useTranslation } from '../../hooks/use-translation';
import { dispatch } from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';

// -----------------------------------------------------------------------------
interface TagProps {
  list: SupportedGame[];
}

// -----------------------------------------------------------------------------
export const SupportedGamesTagList: FC<TagProps> = ({ list }): JSX.Element => {
  const { t } = useTranslation();

  const handleShowSGModal = (): void => {
    dispatch({
      type: AppBusEventType.App.ShowSupportedGamesModal,
      data: { isOpen: true },
    });
  };

  return (
    <>
      {list.slice(0, 10).map((tagItem, index) => {
        const className = tagItem.link ? 'tag-item-link' : 'tag-item';

        return (
          <li className={className} key={index}>
            {tagItem.link && (
              <a href={tagItem.link}>
                {tagItem.iconSrc && (
                  <img src={tagItem.iconSrc} alt={tagItem.name} />
                )}
                {tagItem.name}
              </a>
            )}
            {!tagItem.link && (
              <>
                {tagItem.iconSrc && (
                  <img src={tagItem.iconSrc} alt={tagItem.name} />
                )}
                {tagItem.name}
              </>
            )}
          </li>
        );
      })}

      {list.length >= 10 && (
        <li className='tag-item-link'>
          <button onClick={handleShowSGModal}>
            {t('allGames')} {/* ({list.length}) */}
          </button>
        </li>
      )}
    </>
  );
};
