'use client';

import { useContext, useEffect, useRef, useState } from 'react';
import SpriteIcon from '../shared/sprite-icon';
import AppPageContext from '../../context/app-page-context';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { FullScreenSliderModal } from './full-screen-slider-modal/full-screen-slider-modal';
import { useSliderImageSorting } from '../../hooks/use-slider-image-sorting';
import { SliderImageItem } from './slider-image-item';
import { SliderVideoThumb } from './slider-video-thumb-item';

export const ImageVideoSlider = (): JSX.Element => {
  const { app } = useContext(AppPageContext);

  const slides =  useSliderImageSorting(app.richDetails.richScreenShots);

  const [slideIndex, setSlideIndex] = useState<number>(-1);

  const appImageSlider = useRef<HTMLDivElement>(null);
  const navigationNext = useRef<HTMLButtonElement>(null);
  const navigationPrev = useRef<HTMLButtonElement>(null);
  const sliderPagination = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const imageSliderConfig = new Swiper(appImageSlider.current, {
      speed: 700,
      centeredSlides: false,
      modules: [Navigation, Pagination],
      navigation: {
        nextEl: navigationNext.current,
        prevEl: navigationPrev.current,
      },
      breakpoints: { // Responsive breakpoints
        320: {
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          pagination: {
            el: sliderPagination.current,
            clickable: true,
          },
        },
        550: {
          slidesPerView: 'auto',
          slidesPerGroup: 2,
        },
      }
    });

    imageSliderConfig.on('slideChange', () => {
      imageSliderConfig.pagination.render();
      imageSliderConfig.pagination.update();
    });
  }, []);

  return (
    <>
      <section className='image-slider-section'>
        <div className='image-slider-wrapper'>
          {app.richDetails.richScreenShots.length > 2 &&
            <button
              className='image-slider-next'
              ref={navigationNext}
            >
              <SpriteIcon icon='sliderArrow' />
            </button>
          }
          <div
            className='swiper image-slider'
            ref={appImageSlider}
          >
            <ul className='images-list swiper-wrapper'>
              {slides && slides.map((slide, index) => {
                return (
                  <li className='swiper-slide' key={index}>
                    {slide.type == 'img' &&
                      <SliderImageItem
                        src={slide.asset}
                        alt={app.title}
                        onClick={()=> {
                          setTimeout(() => {
                            setSlideIndex(index);
                          }, 100);
                        }}
                      />
                    }
                    {slide.type == 'video' &&
                      <SliderVideoThumb
                        ytID={slide.asset} 
                        alt={app.title}
                        onClick={()=> {
                          setTimeout(() => {
                            setSlideIndex(index);
                          }, 100);
                        }}
                      />
                    }
                  </li>
                );
              })}
            </ul>
          </div>
          {app.richDetails.richScreenShots.length > 2 &&
            <button
              className='image-slider-prev'
              ref={navigationPrev}
            >
              <SpriteIcon icon='sliderArrow' />
            </button>
          }
        </div>

        {/* <!--pagination only visible in mobile--> */}
        <div className='image-slider-pagination' ref={sliderPagination} />
      </section>

      <FullScreenSliderModal
        slideIndex={slideIndex}
        onClickResetSlideIndex={() => {
          setSlideIndex(-1);
        }}
      />
    </>
  );
};
