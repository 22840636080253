import { FC } from 'react';
import { SupportedGame } from '../../interfaces/domain/ow-app.modal';
import { PreviewItemImage } from './preview-item-img';

// -----------------------------------------------------------------------------
interface SGItemProps {
  list: SupportedGame[];
}

// -----------------------------------------------------------------------------
export const SGPreviewItem: FC<SGItemProps> = ({ list }): JSX.Element => {
  if (!list || list.length === 0) {
    return (<></>);
  }

  const filteredList = list.filter(item => {
    return item?.featuredGame;
  });

  const sliceEnd = filteredList.length >= 4 ? 4 : filteredList.length;

  return (
    <>
      {filteredList.slice(0, sliceEnd).map((item, index) => {

        return (
          <li className='stats-box-item' key={index}>
            {item.link &&
              <a href={item.link}>
                {item.iconSrc &&
                  <PreviewItemImage item={item} />
                }
              </a>
            }
            {!item.link &&
              <>
                {item.iconSrc &&
                  <PreviewItemImage item={item} />
                }
              </>
            }
          </li>
        );

      })}
    </>
  );
};
