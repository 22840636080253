'use client';

import useIsMobile from '../../hooks/use-is-mobile';
import { useTranslation } from '../../hooks/use-translation';
import SpriteIcon from '../shared/sprite-icon';

export const DesktopNotice = (): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const displayType = isMobile ? 'flex' : 'none';

  return (
    <div style={{ display: `${displayType}` }} className='desktop-notice'>
      <SpriteIcon icon='alert' />
      <span>{t('desktopOnly')}</span>
    </div>
  );
};
