const kMailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

interface UseEmailValidationHook {
  validate: (value: string) => boolean;
}

export const useEmailValidation = (): UseEmailValidationHook => {
  const validate = (value: string): boolean => {
    return kMailFormat.test(value);
  };

  return {
    validate
  };
};