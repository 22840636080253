'use client';

import {useContext, useState } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';
import { useClassName } from '../../hooks/use-class-name';
import SpriteIcon from '../shared/sprite-icon';
import useBus from '../../hooks/use-bus';
import { AppBusEventType } from '../../interfaces/bus-events';
import { SupportedGamesModal } from './supported-games-modal';

export const SupportedGamesModalWrapper = (): JSX.Element => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const supportedGames = app.richDetails?.supportedGames || null;

  const handleOpenClose = (): void => {
    setIsOpen(current => !current);
  };

  const modalClassName = useClassName({
    'modal-is-open': isOpen,
  }, 'supported-games-modal-container');

  useBus(AppBusEventType.App.ShowSupportedGamesModal, event => {
    setIsOpen(event.data.isOpen);
  });

  if (!supportedGames || supportedGames.length === 0) {
    return (<></>);
  }

  return (
    <div className='supported-games-modal'>

      <button
        className='show-supported-games-modal-button'
        onClick={handleOpenClose}
        data-tooltip={t('viewAll')}
      >
        {t('allGames')} {/* ({supportedGames.length}) */}
      </button>

      <div className={modalClassName}>
        <div className='supported-games-modal-main'>

          <button
            className='clean-icon-btn close-modal'
            onClick={handleOpenClose}
          >
            <SpriteIcon icon='closeSmall' />
          </button>

          {!app.richDetails.extSupportedGamesModal &&
            <SupportedGamesModal />
          }

          {app.richDetails.extSupportedGamesModal &&
            <iframe src={app.richDetails.extSupportedGamesModal} />
          }

        </div>
        <div className='bg' onClick={handleOpenClose} />
      </div>
    </div>
  );
};
