// small tooltips
// show tooltip for navigation item refer code from: https://github.com/zoltantothcom/vanilla-js-tooltip/blob/master/scripts/vanilla-js-tooltip.js

export const handleToolTipOnOver = (e: { target: any; }): void => {
  if (window.innerWidth < 1024) return; // don't run on mobile and tablets
  if (!e.target.hasAttribute('data-tooltip')) return; // don't run tooltips for elements if tooltip attribute isn't defined

  const toolTipParent = e.target as HTMLElement;
  let position = toolTipParent.getAttribute('tooltip-position');
  position = position ? position : 'top';

  const tooltip = document.createElement('div');
  tooltip.className = `tool-tip ${position}`;
  tooltip.innerHTML = toolTipParent.getAttribute('data-tooltip');
  document.body.appendChild(tooltip);

  // eslint-disable-next-line prefer-const
  let toolTipCords = toolTipParent.getBoundingClientRect(),
    left: number,
    top: number;

  const toolTipRect = tooltip.getBoundingClientRect();
  const triangleSize = 8;
  const tooltipOffset = 14;
  const windowSafeTooltipMargin = 24; // distance for the tooltip from the window's sides
  let tooltipPointerOffsetX = 0;
  let tooltipPointerOffsetY = 0;
  const windowRightEdge = window.innerWidth - windowSafeTooltipMargin;
  const windowBottomEdge = window.innerHeight - windowSafeTooltipMargin;

  switch (position) {

    case 'right':

      left = toolTipCords.right + toolTipParent.offsetWidth / 2;
      top = (Number(toolTipCords.top) + Number(toolTipCords.bottom)) / 2
        - tooltip.offsetHeight / 2;

      if (Number(toolTipCords.right) + tooltip.offsetWidth >
        document.documentElement.clientWidth) {
        left = document.documentElement.clientWidth - tooltip.offsetWidth;
      }

      break;

    case 'left':

      left = toolTipCords.left - tooltip.offsetWidth - triangleSize;
      top = (Number(toolTipCords.top) + Number(toolTipCords.bottom)) / 2
        - tooltip.offsetHeight / 2;

      break;

    case 'bottom':

      left = Number(toolTipCords.left) +
        ((toolTipCords.width - tooltip.offsetWidth) / 2);
      top = Number(toolTipCords.bottom) + 2;

      break;

    case 'top':

      left = Number(toolTipCords.left) +
        ((toolTipCords.width - tooltip.offsetWidth) / 2);
      top = Number(toolTipCords.top) - tooltip.offsetHeight - tooltipOffset;

      break;

  }

  // distance from window right edge
  const availableWidth = windowRightEdge - left;
  // when there isn't enough space for the tooltip
  if (availableWidth < toolTipRect.width) {
    tooltipPointerOffsetX = toolTipRect.width - availableWidth;
    left = left - tooltipPointerOffsetX;
  }

  const availableHeight = windowBottomEdge - top; // distance from window bottom
  // when there isn't enough space below and the tooltip opens down
  if (position == 'bottom' && availableHeight < toolTipRect.height) {
    position = 'top'; // change the tooltip to open above
    // change coords for that
    top = Number(toolTipCords.top) - tooltip.offsetHeight;
  }
  // when there isn't enough space below and it opens left or right
  if ((position == 'left' || position == 'right') &&
    availableHeight < toolTipRect.height) {
    tooltipPointerOffsetY = toolTipRect.height - availableHeight;
    top = top - tooltipPointerOffsetY; // change coords for that
  }

  left = (left < 0) ? Number(toolTipCords.left) : left;
  top  = (top < 0) ? Number(toolTipCords.bottom) : top;

  // the className determines where the triangle is pointing
  tooltip.className = `tool-tip ${position}`;

  tooltip.style.left = left + 'px';
  tooltip.style.top  = top + pageYOffset + 'px';
  // if the tooltip is offset to one side, 
  // this changes the position of the triangle
  tooltip.style.setProperty('--pointer-offset-x',
    (tooltipPointerOffsetX - triangleSize/2) + 'px');
  // if the tooltip is offset to one side, this changes the position
  // of the triangle
  tooltip.style.setProperty('--pointer-offset-y',
    (tooltipPointerOffsetY - triangleSize/2) + 'px');
};

// remove tooltip from dom
export const handleToolTipMouseOut = (e: { target: any; }): void => {
  const elmTarget = e.target as HTMLElement;
  if (elmTarget.hasAttribute('data-tooltip')) {
    setTimeout(() => {
      if (document.querySelector('.tool-tip')) {
        const allStrayTooltips =  document.querySelectorAll('.tool-tip');

        allStrayTooltips.forEach(toolTip => {
          toolTip.remove();
        });
      }
    }, 0);
  }
};