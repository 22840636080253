'use client';
import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';

export const CustomScripts = (): JSX.Element  => {
  const { app } = useContext(AppPageContext);

  const customScripts = app.richDetails?.customScripts;
  if (!customScripts || customScripts == '') {
    return (
      <>
      </>
    );
  }

  return (
    <script dangerouslySetInnerHTML={{ __html: customScripts }} />
  );

};
