'use client';

import { useContext, useMemo, useRef, useState } from 'react';
import { useClassName } from '../../hooks/use-class-name';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import { kImageFolderUrl } from '../../services/constants';
import SpriteIcon from '../shared/sprite-icon';
import GlobalContext from '../../context/global-context';

export const TechnicalDetails = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { config } = useContext(GlobalContext);

  const { commitmentUrl } = config?.publicRuntimeConfig?.links;
  const buttonElement = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const buttonText = useMemo<string>(() => {
    return isExpanded ? t('readLess') : t('readMore');
  }, [isExpanded]);

  const expendedClassName = useClassName({
    'is-open': isExpanded,
  }, 'app-technical-details');

  const handleExpand = (): void => {
    setIsExpanded(cur => !cur);
  };

  if (app?.richDetails?.isElectron || app?.isElectron) {
    return (<></>);
  }

  return (
    <section className={expendedClassName} id='technical-details'>
      <h2>{t('technicalDetails.title')}</h2>

      <div className='content'>
        <div className='client-info'>
          <h3>
            <SpriteIcon icon='owIcon' />
            {app.title} {t('technicalDetails.powered')}
          </h3>
          <p dangerouslySetInnerHTML={{
            __html: t('technicalDetails.about')
          }}
          />
          <p>{t('technicalDetails.download')}</p>
        </div>

        <button
          className='clean-btn'
          ref={buttonElement}
          onClick={handleExpand}
        >
          {buttonText}
          {!isExpanded &&
          <SpriteIcon icon='plus' />
          }
          {isExpanded &&
          <SpriteIcon icon='minus' />
          }
        </button>

        <div className='expand-content'>
          <h4>{t('technicalDetails.promise')}</h4>

          <ul className='info-list'>
            <li>
              <img 
                src={`${kImageFolderUrl}/client-icons/swords.svg`}
                alt={t('technicalDetails.FPSTitle')}
              />
              <h5>{t('technicalDetails.FPSTitle')}</h5>
              <p>{t('technicalDetails.FPSDesc')}</p>
            </li>

            <li>
              <img 
                src={`${kImageFolderUrl}/client-icons/document.svg`} 
                alt={t('technicalDetails.compliantTitle')}
              />
              <h5>{t('technicalDetails.compliantTitle')}</h5>
              <p>{t('technicalDetails.compliantDesc')}</p>
            </li>

            <li>
              <img 
                src={`${kImageFolderUrl}/client-icons/banner.svg`} 
                alt={t('technicalDetails.privacyTitle')}
              />
              <h5>{t('technicalDetails.privacyTitle')}</h5>
              <p>{t('technicalDetails.privacyDesc')}</p>
            </li>
          </ul>

          <a
            href={commitmentUrl}
            target='_blank'
            className='btn-secondary-small' rel='noreferrer'
          >
            {t('technicalDetails.overwolfFaq')}
          </a>
        </div>
      </div>
    </section>
  );
};
