import { FC, UIEvent, useContext, useEffect, useState } from 'react';
import { useClassName } from '../../hooks/use-class-name';
import SpriteIcon from '../shared/sprite-icon';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';
import { Review } from '../../interfaces/domain/reviews.modal';
import { ReviewItem } from './review-item';
import ImgWithPlaceholder from '../shared/img-with-placeholder';
import { kImageFolderUrl } from '../../services/constants';

// -----------------------------------------------------------------------------
interface Props {
  isOpen: boolean;
  reviews: Review[];
  isLoading: boolean;
  allReviewsLoaded: boolean;
  loadMoreReviews: () => void;
  onClickClose: () => void;
}

// -----------------------------------------------------------------------------
export const ReviewsModal: FC<Props> = ({
  isOpen,
  reviews,
  isLoading,
  allReviewsLoaded,
  loadMoreReviews,
  onClickClose,
}): JSX.Element => {

  const { t } = useTranslation();

  const { app } = useContext(AppPageContext);

  const [reachedBottom, setReachedBottom] = useState<boolean>(false);
  const [startSpinner, setSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (isLoading) {
      setSpinner(true);
    }

    if (!isLoading) {
      setReachedBottom(false);
      setSpinner(false);
    }
  }, [isLoading]);

  const className = useClassName({
    'modal-is-open': isOpen
  }, 'reviews-modal', [isOpen]);

  const loadMoreButtonClassName = useClassName(
    {
      'loading': startSpinner,
      'is-active': reachedBottom &&
        reviews.length <= app.initialReviews.totalRateCount,
    },
    'load-more btn-secondary-small',
    [isLoading, reviews, app.initialReviews.totalRateCount]
  );

  const handleScroll = (e: UIEvent<HTMLUListElement>): void => {
    const {scrollHeight, scrollTop, clientHeight} = e.currentTarget;
    const offset = scrollHeight - clientHeight - scrollTop;
    const isAtBottom = Math.abs(offset) - 40 < 1;
    setReachedBottom(isAtBottom);
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className={className}>
      <div className='reviews-modal-main'>
        <button
          className='clean-icon-btn close-modal'
          onClick={onClickClose}
        >
          <SpriteIcon icon='closeSmall' />
        </button>

        <div className='reviews-modal-header'>
          <div className='app-thumb'>
            <ImgWithPlaceholder
              width={56}
              height={56}
              img={app.iconUrl}
              placeholder={`${kImageFolderUrl}/app-page/app-icon-placeholder.webp`}
              alt={app.title}
            />
          </div>
          <div className='app-title'>
            <h2>{app.title}</h2>
            <p>
              {t('appReviews')}
              <span className='reviews-amount-data'>
                {` (${app.initialReviews.totalRateCount})` }
              </span>
            </p>
          </div>
        </div>

        <div className='reviews-modal-content'>
          <ul className='reviews-modal-list' onScroll={handleScroll}>
            {
              reviews.map(review => {

                if (!review?.comment_ID) {
                  return <></>;
                }

                return (
                  <ReviewItem review={review} key={review?.comment_ID} />
                );
              })
            }
          </ul>

          {!allReviewsLoaded && 
              <button
                className={loadMoreButtonClassName}
                onClick={loadMoreReviews}
              >
                <SpriteIcon icon='loader' />
                {t('loadMore')}
              </button>

          }
        </div>
      </div>
      <div className='bg' onClick={onClickClose} />
    </div>
  );
};
