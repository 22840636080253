import { FC, useMemo } from 'react';
import { RateDistribution } from '../../interfaces/domain/reviews.modal';
import SpriteIcon from '../shared/sprite-icon';

// -----------------------------------------------------------------------------
interface Props {
  segment: RateDistribution;
}

// -----------------------------------------------------------------------------
export const ProgressRing: FC<Props> = ({segment}): JSX.Element => {
  const style = useMemo<React.CSSProperties>(() => {
    return { '--progress': segment.percents } as React.CSSProperties;
  }, [segment.percents]);

  return (
    <li>
      <div className='progress-ring' style={style}>

        <span className='rating-type'>
          {segment.stars}
          <SpriteIcon icon='ratingStar' />
        </span>

        <svg viewBox='0 0 72 72' className='ring'>
          <circle className='progress-bg' fill='none' cx='50%' cy='50%' r='32' stroke='var(--surface-tertiary)'  />
          <circle className='progress' cx='50%' cy='50%' r='32' stroke='url(#daily-ring)' />
          <defs>
            <linearGradient id='daily-ring' x1='0' y1='0' x2='72' y2='0' gradientTransform='rotate(90)' gradientUnits='userSpaceOnUse'>
              <stop stopColor='var(--ow-red)' />
              <stop offset='1' stopColor='var(--ow-red)' />
            </linearGradient>
          </defs>
        </svg>

        <progress className='progress-bar' value={`${segment.percents}`} max='100' />

        <span className='progress-percentage'>
          {`${segment.percents}%`}
        </span>

      </div>
    </li>
  );
};
