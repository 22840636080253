import { FC, useEffect, useRef } from 'react';
import { Review } from '../../interfaces/domain/reviews.modal';
import SpriteIcon from '../shared/sprite-icon';
import { ReviewSliderItem } from './review-slider-item';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// -----------------------------------------------------------------------------
interface Props {
  reviews: Review[];
}

// -----------------------------------------------------------------------------
export const ReviewSlider: FC<Props> = ({ reviews }): JSX.Element => {
  const slider = useRef<HTMLDivElement>(null);
  const navigationNext = useRef<HTMLButtonElement>(null);
  const navigationPrev = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    new Swiper(slider.current, {
      speed: 700,
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      modules: [Navigation],
      navigation: {
        nextEl: navigationNext.current,
        prevEl: navigationPrev.current,
      },

      // Responsive breakpoints
      breakpoints: {
        320: {
          spaceBetween: 12,
        },
        800: {
          spaceBetween: 24,
        },
      }
    });
  }, []);

  if (reviews.length < 3) {
    return null;
  }

  return (
    <section className='reviews-slider-section'>
      <div className='reviews-slider-wrapper'>
        <button className='reviews-slider-next' ref={navigationNext}>
          <SpriteIcon icon='sliderArrow' />
        </button>

        <div className='swiper reviews-slider' ref={slider}>
          <ul className='reviews-list swiper-wrapper'>
            {
              reviews.map(review => {
                return (
                  <ReviewSliderItem key={review.comment_ID} review={review} />
                );
              })
            }
          </ul>

        </div>

        <button className='reviews-slider-prev' ref={navigationPrev}>
          <SpriteIcon icon='sliderArrow' />
        </button>
      </div>
    </section>
  );
};
