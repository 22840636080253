'use client';

import { useContext } from 'react';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import { kImageFolderUrl } from '../../services/constants';

export const Inventory = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();
  const inAppAdsDescription =
    app.richDetails?.altContainsAdsTxt ?
      app.richDetails?.altContainsAdsTxt :
      t('inAppAdsDescriptionBody');

  return (
    <section className='app-inventory-section'>
      {
        (app.isPremium || app.doesContainsAds) &&
          <h2>{t('support')} {app.title}</h2>
      }

      <ul className='app-inventory-list'>
        {
          app.doesContainsAds &&
          <li className='app-inventory-item'>
            <div className='asset'>
              <img
                src={`${kImageFolderUrl}/app-page/in-app-ads.webp`}
                alt={t('inAppAds')}
              />
              {t('inAppAds')}
            </div>
            <p>
              {t('inAppAdsDescriptionTitle', { appName: app.title })}
              <span>
                {
                  inAppAdsDescription
                }
              </span>
            </p>
          </li>
        }

        {
          app.isPremium &&
          <li className='app-inventory-item'>
            <div className='asset'>
              <img
                src={`${kImageFolderUrl}/app-page/support-icon-ads.webp`}
                alt={t('premiumAds')}
              />
              {t('premiumAds')}
            </div>
            <p dangerouslySetInnerHTML={{
              __html: t('supportTheDev', {appName: app.title})
            }}
            />
          </li>
        }

      </ul>
    </section>
  );
};
