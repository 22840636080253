'use client';

import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';

export const AppBackground = (): JSX.Element => {
  const { app } = useContext(AppPageContext);

  const bgImageUrl = app.richDetails?.appBackgroundImg || null;

  if (!bgImageUrl) {
    return null;
  }

  return (
    <div
      className='app-background'
      style={{backgroundImage: `url('${bgImageUrl}')`}}
    />
  );
};
