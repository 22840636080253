'use client';

import { OwApp } from '../interfaces/domain/ow-app.modal';
import AppPageContext from './app-page-context';

interface Props {
  children: React.ReactNode;
  app: OwApp;
}

const AppPageContextProvider = (props: Props): JSX.Element => {
  const { app, children } = props;
  return (
    <AppPageContext.Provider value={{ app }}>
      {children}
    </AppPageContext.Provider>
  );
};

export default AppPageContextProvider;
