import { useContext, useEffect, useRef, useState } from 'react';
import { IRemindMeService, RemindMeParams } from '../interfaces/services/remind-me-service.interface';
import { ServicesHandler } from '../services/service-handler';
import GlobalContext from '../context/global-context';

interface UseRemindMeServiceHook {
  isLoading: boolean;
  sendRemindMe(params: RemindMeParams): Promise<boolean>;
}

export const useRemindMeService = (): UseRemindMeServiceHook => {
  const service = useRef<IRemindMeService>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { config } = useContext(GlobalContext);

  useEffect(() => {
    const { remindMeService } = ServicesHandler.getInstance(config);
    service.current = remindMeService;
  }, []);

  const sendRemindMe = async (params: RemindMeParams): Promise<boolean> => {
    try {
      setIsLoading(true);
      const res = await service.current.sendRemindMe(params);
      setIsLoading(false);
      return res;
    } catch (e) {
      throw new Error('Failed to POST', e);
    }
  };

  return {
    isLoading,
    sendRemindMe
  };
};
