'use client';

import AppPageContext from '../context/app-page-context';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from '../hooks/use-translation';
import { useBuildDownloadUrl } from '../hooks/use-download-url-builder';
import { dispatch } from '../hooks/use-bus';
import { AppBusEventType } from '../interfaces/bus-events';
import Utils from '../services/utils';
import { useAnalytics } from '../hooks/use-analytics';
import { kImageFolderUrl } from '../services/constants';
import ImgWithPlaceholder from './shared/img-with-placeholder';
import { useClassName } from '../hooks/use-class-name';
import useOS from '../hooks/use-os';
import { getQueryParam } from '../hooks/use-get-query-param';

export const AppHeader = (): JSX.Element => {
  const analytics = useAnalytics();
  const { app } = useContext(AppPageContext);
  const OS = useOS();

  const {
    title,
    iconUrl,
    author,
    id: extensionId,
    isFree,
    doesContainsAds,
    isPremium,
    shortDescription
  } = app;
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const headerHolder = useRef<HTMLDivElement>(null);
  const downloadBtn = useRef<HTMLAnchorElement>(null);
  const effectRan = useRef<boolean>(false);

  useEffect(() => {
    const onScroll = (): void => {
      const distanceToTop = headerHolder.current.getBoundingClientRect().top;
      // 86 is the header height
      if (distanceToTop < 86 && window.innerWidth > 1024) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isSticky]);

  useEffect(() => {
    if (effectRan.current) return; // Prevent execution on subsequent renders
    effectRan.current = true;

    const startDownload = getQueryParam(location.search, 'startdownload');
    if (startDownload === 'true') {
      setTimeout(() => {
        handleStartDownloadParam();
      }, 500);
    }

  }, []);

  const handleStartDownloadParam = (): void => {
    downloadBtn.current.click();
  };

  const stickyHeaderClassNames = useClassName({
    'floats': isSticky
  }, 'app-info-sticky-header');
 
  const downloadUrl = useBuildDownloadUrl({
    channel: 'web_dl_btn',
    extensionId: extensionId,
    name: title,
    utmContent: 'new-light'
  });

  const handleClickDownload = (event: React.MouseEvent<HTMLElement>): void => {
    Utils.InstallerDownloadOverwrite(event, extensionId);
    const autoDownload =
      getQueryParam(location.search, 'startdownload') ? true : false;
    analytics.track('appstore_download_clicked', {
      'appId': extensionId,
      'appName': title,
      'component': 'App one page',
      'platform': 'Web',
      'autoDownload': autoDownload,
    });
    analytics.gtagPush({
      event: 'app_download_cta',
      app_name: title,
      app_id: extensionId,
      app_os: OS,
    });

    handleShowDownloadModal();
  };

  const handleShowDownloadModal = (): void => {
    dispatch({
      type: AppBusEventType.App.ShowDownloadModal,
      data: { isOpen: true, currentApp: {appId: extensionId, appName: title} }
    });
  };

  const { t } = useTranslation();

  const appShortDescription = app.richDetails?.altShortDescription ?
    app.richDetails.altShortDescription : shortDescription;

  const appAuthorName = app.richDetails?.authorNameOverride ?
    app.richDetails.authorNameOverride : author;

  return (
    <section className='app-header-section'>

      <div className='app-header-content' ref={headerHolder}>
        <div className={stickyHeaderClassNames}>
          <div className='app-info-sticky-header-inner'>
            <div className='app-info'>
              <div className='app-thumb'>
                <ImgWithPlaceholder
                  width={56}
                  height={56}
                  img={iconUrl}
                  placeholder={`${kImageFolderUrl}/app-page/app-icon-placeholder.webp`}
                  alt={title}
                />
              </div>
              <div className='app-title'>
                <h1>{title}</h1>
                <span><span>{t('by')}</span> {appAuthorName}</span>
              </div>
            </div>

            <div className='app-download-area'>
              <a
                href={downloadUrl}
                onClick={handleClickDownload}
                className='btn-primary'
                download={t('appInstaller', {title})}
                ref={downloadBtn}
              >
                {t('downloadApp')}
              </a>
              <ul className='app-inventory-list'>

                {
                  isFree &&
                  <li className='app-inventory-item'>{t('freeOption')}</li>
                }
                {
                  doesContainsAds &&
                  <li className='app-inventory-item'>{t('containsAds')}</li>
                }
                {
                  isPremium &&
                  <li className='app-inventory-item'>
                    {t('subscriptionOption')}
                  </li>
                }

              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='app-description'>
        <p dangerouslySetInnerHTML={{__html: appShortDescription}} />
      </div>

    </section>
  );
};

