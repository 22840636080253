import { useState, useEffect } from 'react';
import Image from 'next/image';
import React from 'react';

interface ImgWithPlaceholderProps {
  id?: string;
  img: string;
  placeholder: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
}

const ImgWithPlaceholder = React.memo((props: ImgWithPlaceholderProps) => {
  ImgWithPlaceholder.displayName = 'ImgWithPlaceholder';

  const {
    id,
    img,
    placeholder,
    alt,
    className,
    width,
    height
  } = props;

  const imgSrc = !img ? placeholder : img; {/* empty string check */}
  const [src, setSrc] = useState<string>(imgSrc);

  useEffect(() => {
    if (!img) {
      setSrc(placeholder);
    } else {
      setSrc(img);
    }
  }, [img, placeholder]);

  const handleError = (): void => {
    setSrc(placeholder);
    // console.log('err')
  };

  return (
    <Image
      id={id}
      className={className}
      src={src}
      alt={alt}
      onError={handleError}
      width={width}
      height={height}
    />
  );
});

export default ImgWithPlaceholder;
