'use client';
import { useContext } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useClassName } from '../../hooks/use-class-name';
import { AboutContent } from '../about-content/about-content';
import { AppHeader } from '../app-header';
import { DownloadModalWrapper } from '../download-modal/download-modal-wrapper';
import { Inventory } from '../inventory/inventory';
import { Reviews } from '../reviews/reviews';
import { Stats } from '../stats/stats';
import { TechnicalDetails } from '../technical-details/technical-details';
import { AppTracking } from '../tracking/app-tracking/app-tracking';
import { RemindMeMobile } from '../remind-me-mobile/remind-me-mobile';
import { AppBackground } from '../app-background/app-background';
import { Tags } from '../tags/tags';
import { AboutCreator } from '../about-creator/about-creator';
import { SliderWrapper } from '../slider/slider-wrapper';
import { DiscoverySection } from '../discovery-section/discovery-section';
import { DesktopNotice } from '../desktop-notice/desktop-notice';
import { CustomScripts } from '../custom-scripts/custom-scripts';

export const AppContainer = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  
  const appPageLayout = useClassName({
    'light-template': app.richDetails?.appBackgroundImg ? false : true
  }, 'app-page-layout');

  return (
    <div className={appPageLayout}>
      <DesktopNotice />
      <AppBackground />
      <div className='app-page-sections'>
        <Tags tagsType='Normal' />
        <AppHeader />
        <AppTracking />
        <DownloadModalWrapper />
        <Stats />
        <SliderWrapper />
        <AboutContent />
        <Reviews />
        <AboutCreator />
        <Inventory />
        <TechnicalDetails />
      </div>
      <DiscoverySection />
      <RemindMeMobile />
      <CustomScripts />
    </div>
  );
};