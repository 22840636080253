'use client';

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useClassName } from '../../hooks/use-class-name';
import { useTranslation } from '../../hooks/use-translation';
import AppPageContext from '../../context/app-page-context';
import { Tags } from '../tags/tags';

export const AboutContent = (): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const innerContent = useRef<HTMLDivElement>(null);
  const buttonElement = useRef<HTMLButtonElement>(null);

  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [expandContainer, setExpandContainer] = useState<boolean>(false);
  const [showReadMore, setShowReadMore] = useState<boolean>(false);

  const buttonText = useMemo<string>(() => {
    return isExpanded ? t('readLess') : t('readMore');
  }, [isExpanded, t]);

  const innerContentClassName = useClassName({
    'expand-container': expandContainer,
    'is-open': isExpanded,
  }, 'app-about-content-inner', [isExpanded]);

  const handleExpand = (): void => {
    setIsExpanded(cur => !cur);
    const readMoreContainer = innerContent.current;

    if (readMoreContainer.style.maxHeight) {
      readMoreContainer.style.maxHeight = null;
    } else {
      readMoreContainer.style.maxHeight = readMoreContainer.scrollHeight + 'px';
    }

    buttonElement.current.blur();
  };

  useEffect(() => {
    if (innerContent.current.scrollHeight > 600) {
      setShowReadMore(true);
      setExpandContainer(true);
    }
  }, []);

  return (
    <article className='app-about-content'>
      <h2 className='about-title'>{t('aboutTheApp')}</h2>

      <div className={innerContentClassName} ref={innerContent}>
        <div className='main-content'>
          <div className='app-info' dangerouslySetInnerHTML={{
            __html: app.fullDescription
          }}
          />
        </div>
        <Tags tagsType='AdditionalFeatures' />
        <Tags tagsType='SupportedGames' />
      </div>

      { showReadMore &&
        <button
          id='readMoreExpandBtn'
          className='expand-btn btn-secondary-small'
          ref={buttonElement}
          onClick={handleExpand}
        >
          {buttonText}
        </button>
      }
    </article>

  );
};
