import { FC } from 'react';
import SpriteIcon from '../shared/sprite-icon';
import { useTranslation } from '../../hooks/use-translation';

// -----------------------------------------------------------------------------
interface FilterProps {
  searchText: string;
  placeholder: string;
  clearSearch: () => void;
  onSearchTextChange: (e: string) => void;
}

// -----------------------------------------------------------------------------
export const GameSearchFilter: FC<FilterProps> = ({
  placeholder,
  clearSearch,
  onSearchTextChange,
  searchText
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className='search-input'>
      <SpriteIcon icon='search' />

      <input
        placeholder={placeholder}
        onChange={e => onSearchTextChange(e.target.value)}
        type='search'
        value={searchText}
      />

      <button
        title={t('clearSearch')}
        className='clear-search'
        onClick={() => clearSearch()}
      >
        <SpriteIcon icon='closeSmall' />
      </button>
    </div>
  );
};
