import { BrowseMoreApps } from '../browse-more-apps/browse-more-apps';
import { Pairing } from '../pairing/pairing';

// -----------------------------------------------------------------------------
export const DiscoverySection: React.FC = () => {
  return (
    <div className='discovery-sections'>
      <BrowseMoreApps />
      <Pairing />
    </div>
  );
};