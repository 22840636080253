import { FC } from 'react';

// -----------------------------------------------------------------------------
interface ImageItemProps {
  src: string;
  alt: string;
  onClick: () => void;
}

// -----------------------------------------------------------------------------
export const SliderImageItem: FC<ImageItemProps> = ({
  src, alt, onClick
}): JSX.Element => {

  return (
    <img
      src={src}
      alt={`${alt} screenshot image`}
      onClick={onClick}
    />
  );
};
