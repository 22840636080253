'use client';

import { FC, useContext } from 'react';
import AppPageContext from '../../context/app-page-context';
import { useTranslation } from '../../hooks/use-translation';
import { TagList } from './tag-list';
import { FeaturedTagList } from './featured-tag-list';
import { SupportedGamesTagList } from './supported-games-tag-list';
import { useClassName } from '../../hooks/use-class-name';

// -----------------------------------------------------------------------------
interface TagsProps {
  tagsType: TagsType;
}

// -----------------------------------------------------------------------------
type TagsType =
  | 'Normal'
  | 'AdditionalFeatures'
  | 'SupportedGames';

// -----------------------------------------------------------------------------
export const Tags: FC<TagsProps> = ({ tagsType }): JSX.Element => {
  const { app } = useContext(AppPageContext);
  const { t } = useTranslation();

  const tagsClassName = useClassName({
    'features-tag-list': tagsType === 'AdditionalFeatures',
    'supported-games-tag-list': tagsType === 'SupportedGames'
  }, 'tags');

  // ---------------------------------------------------------------------------
  const obtainTagsTitle = (tagType: TagsType): string => {
    if (tagType ==='AdditionalFeatures') {
      return t('otherFeatures');
    }

    if (tagType ==='SupportedGames') {
      return t('supportedGames');
    }

    return '';
  };

  // ---------------------------------------------------------------------------
  const ObtainTags = ({ tagType }): JSX.Element => {
    if (tagType ==='AdditionalFeatures' &&
      app.richDetails?.additionalFeatures?.length > 0) {
      return (
        <>
          <h4 className='tag-group-title'>{obtainTagsTitle(tagType)}</h4>
          <ul className='tag-list'>
            <FeaturedTagList list={app.richDetails.additionalFeatures} />
          </ul>
        </>
      );
    }

    if (tagType === 'SupportedGames' &&
      app.richDetails?.supportedGames &&
      app.richDetails?.supportedGames.length > 0) {
      return (
        <>
          <h4 className='tag-group-title'>{obtainTagsTitle(tagType)}</h4>
          <ul className='tag-list'>
            <SupportedGamesTagList list={app.richDetails.supportedGames} />
          </ul>
        </>

      );
    }

    if (tagType === 'Normal' &&
      app.richDetails?.tags && app.richDetails?.tags.length > 0) {
      return (
        <ul className='tag-list'>
          <TagList list={app.richDetails.tags} />
        </ul>
      );
    }

    return (<></>);
  };

  return (
    <div className={tagsClassName}>
      <ObtainTags tagType={tagsType} />
    </div>
  );
};
