import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Review } from '../../interfaces/domain/reviews.modal';
import ImgWithPlaceholder from '../shared/img-with-placeholder';
import { useClassName } from '../../hooks/use-class-name';
import { useTranslation } from '../../hooks/use-translation';
import { useDateFormat } from '../../hooks/use-date-format';
import { kImageFolderUrl } from '../../services/constants';
import AppPageContext from '../../context/app-page-context';

const kLongCommentLength = 380;

// -----------------------------------------------------------------------------
interface Props {
  review: Review;
}

// -----------------------------------------------------------------------------
export const ReviewItem: FC<Props> = ({ review }): JSX.Element => {
  const { t } = useTranslation();
  const { app } = useContext(AppPageContext);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [showMoreReply, setShowMoreReply] = useState<boolean>(false);

  const appAuthorName = app.richDetails?.authorNameOverride ?
    app.richDetails.authorNameOverride : app.author;

  const rateStyle = useMemo<React.CSSProperties>(() => {
    return { '--rating': review.rate } as React.CSSProperties;
  }, [review.rate]);

  const showReadMore = useCallback((content: string) => {
    if (!content?.length) {
      return false;
    }
    return content.length > kLongCommentLength;
  }, []);

  const userReviewClassName = useClassName({
    'read-more': showReadMore(review.comment_content),
    'is-shown': showMore,
  }, 'user-review', [review.comment_content]);

  const replyReviewClassName = useClassName({
    'read-more': showReadMore(review.replies[0]?.body),
    'is-shown': showMoreReply,
  }, 'response-content', [review.replies[0]?.body]);

  const cleanContent = (content: string): string => {
    if (!content) {
      return '';
    }

    return content.replace(/\n/g, '<br />');
  };

  return (
    <li className='review-item'>
      <div className='review'>
        <div className='review-header'>
          <div className='user-avatar'>
            <ImgWithPlaceholder
              id='row-image'
              width={48}
              height={48}
              img={review.comment_author_url}
              placeholder={`${kImageFolderUrl}/reviews/user-review-placeholder.webp`}
              alt={review.comment_author}
            />
          </div>
          <div className='user-info'>
            <h2 className='title'>
              <span>{review.comment_author}</span>
              <span className='date'>{useDateFormat(review.comment_date_gmt)}</span>
            </h2>
            <div className='ratings'>
              <div className='star-rating' style={rateStyle} />
            </div>
          </div>
        </div>

        {
          review.comment_ID &&

          <div className='review-content'>
            <div className={userReviewClassName} >
              {review?.comment_content ?? ''}
            </div>

            {
              showReadMore(review?.comment_content ?? '') &&
              <button
                className='clean-btn'
                onClick={() => setShowMore(cur => !cur)}
              >
                {showMore ? t('readLess') : t('readMore')}
              </button>
            }

            {
              review.replies.length > 0 &&

              <div className='creator-response'>
                <h3>
                  {appAuthorName} <span>{t('creatorResponse')}</span>
                </h3>
                <div className={replyReviewClassName} dangerouslySetInnerHTML={{
                  __html: cleanContent(review.replies[0].body)
                }}
                />
                {
                  showReadMore(review.replies[0].body) &&
                  <button
                    className='clean-btn'
                    onClick={() => setShowMoreReply(cur => !cur)}
                  >
                    {showMore ? t('readLess') : t('readMore')}
                  </button>
                }
              </div>
            }
          </div>
        }
      </div>
    </li>
  );
};